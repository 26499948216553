import React, { useEffect, useState } from 'react'
import { PropTypes } from 'prop-types'
import { injectIntl, FormattedMessage } from 'react-intl'
import { Formik, Form, useFormikContext } from 'formik'
import { Box, Flex, Heading } from 'rebass/styled-components'

import { getValidationSchema } from './validationSchema'
import TextInput from '../text-input'
import Textarea from '../textarea'
import Select from '../select'
import { BuyButton } from '../button'
import { usePersistedState } from '../../hooks/usePersistedState'
import { getGeolocation } from '../../utils/getGeolocation'
import { getCountries } from '../../utils/getCountries'
import CheckboxInput from '../checkbox-input'
import StyledRightUpIcon from '../right-up-icon'

const Location = ({ onCountryChange }) => {
  const { setFieldValue } = useFormikContext()
  const [country, setCountry] = useState(null)

  useEffect(() => {
    getGeolocation()
      .then(response => {
        if ('countryCode' in response) {
          const countryCode = response.countryCode.toLowerCase()
          setCountry(countryCode)
          setFieldValue('country', countryCode)
          onCountryChange(countryCode)
        }
      })
  }, [country])

  return null
}

const OrderForm = ({ intl, onSubmit, isSubmitting, onCountryChange }) => {
  const initialValues = {
    firstname: '',
    lastname: '',
    email: '',
    message: '',
    addressLine1: '',
    addressLine2: '',
    city: '',
    state: '',
    postalCode: '',
    country: '',
    acceptTerms: [],
  }
  const [formValues, persistOrderValues] = usePersistedState('order-form-fields', initialValues)
  const countries = getCountries()

  const handleSubmit = (values, { resetForm, isValid }) => {
    // This persists form values in localStorage (if available)
    if (isValid) {
      persistOrderValues({
        firstname: values.firstname,
        lastname: values.lastname,
        email: values.email,
        addressLine1: values.addressLine1,
        addressLine2: values.addressLine2 || '',
        city: values.city,
        state: values.state || '',
        postalCode: values.postalCode,
        country: values.countryCode.toLowerCase(),
        acceptTerms: values.acceptTerms,
      })
    }
    onSubmit(values, resetForm)
  }

  return (
    <Box>
      <Formik
        initialValues={formValues}
        validateOnMount
        validationSchema={getValidationSchema(intl)}
        onSubmit={handleSubmit}
      >
        {({ isValid, handleChange }) => (
          <Form>
            <Heading as="h2" mb={3}>
              <FormattedMessage id="product.order.personalInformation" defaultMessage="Personal information" />
            </Heading>

            <Flex flexWrap="wrap" justifyContent="space-between">
              <Box width="49%">
                <TextInput
                  label={intl.formatMessage({ id: 'order.firstname.label', defaultMessage: 'First name' })}
                  name="firstname"
                  type="text"
                  placeholder={intl.formatMessage({ id: 'order.firstname.placeholder', defaultMessage: 'Your first name' })}
                  autoComplete="given-name"
                />
              </Box>

              <Box width="49%">
                <TextInput
                  label={intl.formatMessage({ id: 'order.lastname.label', defaultMessage: 'Last name' })}
                  name="lastname"
                  type="text"
                  placeholder={intl.formatMessage({ id: 'order.lastname.placeholder', defaultMessage: 'Your last name' })}
                  autoComplete="family-name"
                />
              </Box>
            </Flex>

            <TextInput
              label={intl.formatMessage({ id: 'order.email.label', defaultMessage: 'E-mail' })}
              labelDescription={intl.formatMessage({ id: 'order.email.labelDescription', defaultMessage: 'We will use this e-mail address to contact you.' })}
              name="email"
              type="email"
              placeholder={intl.formatMessage({ id: 'order.email.placeholder', defaultMessage: 'email@example.com' })}
              autoComplete="email"
            />

            <Textarea
              label={intl.formatMessage({ id: 'order.message.label' })}
              labelDescription={intl.formatMessage({ id: 'order.message.labelDescription', defaultMessage: 'Tell us more about customization you want etc.' })}
              name="message"
              rows="4"
              optional
              placeholder={intl.formatMessage({ id: 'order.message.placeholder', defaultMessage: 'Specify your requirements...' })}
            />

            <Heading as="h2" mb={3}>
              <FormattedMessage id="product.order.shippingAddress" defaultMessage="Shipping address" />
            </Heading>

            <TextInput
              label={intl.formatMessage({ id: 'order.addressLine1.label', defaultMessage: 'Address line 1' })}
              name="addressLine1"
              placeholder={intl.formatMessage({ id: 'order.addressLine1.placeholder', defaultMessage: 'Street address etc.' })}
              autoComplete="address-line1"
            />

            <TextInput
              label={intl.formatMessage({ id: 'order.addressLine2.label', defaultMessage: 'Address line 2' })}
              name="addressLine2"
              placeholder={intl.formatMessage({ id: 'order.addressLine2.placeholder', defaultMessage: 'Apt. number, house etc.' })}
              autoComplete="address-line2"
              optional
            />

            <TextInput
              label={intl.formatMessage({ id: 'order.city.label', defaultMessage: 'City' })}
              name="city"
              placeholder={intl.formatMessage({ id: 'order.city.placeholder', defaultMessage: 'e.g. Miami' })}
              autoComplete="address-level2"
            />

            <Flex justifyContent="space-between">
              <Box flexBasis="60%">
                <TextInput
                  label={intl.formatMessage({ id: 'order.state.label', defaultMessage: 'State' })}
                  name="state"
                  placeholder={intl.formatMessage({ id: 'order.state.placeholder', defaultMessage: 'e.g. FL' })}
                  autoComplete="address-level1"
                  optional
                />
              </Box>

              <Box flexBasis="37%">
                <TextInput
                  label={intl.formatMessage({ id: 'order.postalCode.label', defaultMessage: 'Postal code' })}
                  name="postalCode"
                  placeholder={intl.formatMessage({ id: 'order.postalCode.placeholder', defaultMessage: 'e.g. 33192' })}
                  autoComplete="postal-code"
                />
              </Box>
            </Flex>

            <Select
              label={intl.formatMessage({ id: 'order.country.label', defaultMessage: 'Country' })}
              name="country"
              placeholder={intl.formatMessage({ id: 'order.country.placeholder', defaultMessage: 'Country' })}
              autoComplete="country"
              onChange={e => {
                onCountryChange(e.currentTarget.value)
                handleChange(e)
              }}
            >
              <option value="">
                {intl.formatMessage({ id: 'order.country.prompt', defaultMessage: 'Select country...' })}
              </option>
              {countries && countries.map(country => {
                return (
                  <option value={country.code.toLowerCase()} key={country.code}>
                    {country.name}
                  </option>
                )
              })}
            </Select>

            <Location onCountryChange={countryCode => onCountryChange(countryCode)} />

            <Box sx={{ mb: 3 }}>
              <CheckboxInput
                label={
                  <>
                    <FormattedMessage id='order.acceptTerms.label' defaultMessage='I accept <a>Terms & conditions</a>' values={{
                      a: (str) => <a href="/terms-and-conditions/" target="_blank">{str}</a>,
                    }} />
                    <StyledRightUpIcon />
                  </>
                }
                name="acceptTerms"
                value="accept"
              />
            </Box>

            <BuyButton type="submit" disabled={isSubmitting || !isValid}>
              {isSubmitting ?
                <FormattedMessage id="product.order.placingOrder" defaultMessage="Placing order..." />
                :
                <FormattedMessage id="product.order.placeOrderButtonLabel" defaultMessage="Place order" />
              }
            </BuyButton>
          </Form>
        )}
      </Formik>
    </Box>
  )
}

OrderForm.propTypes = {
  intl: PropTypes.object,
  onSubmit: PropTypes.func,
  isSubmitting: PropTypes.bool,
  onCountryChange: PropTypes.func,
}

export default injectIntl(OrderForm)
