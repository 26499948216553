import React, { useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { injectIntl } from 'react-intl'
import Img from 'gatsby-image'
import { Box, Flex } from 'rebass/styled-components'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import ReactPlayer from 'react-player'

import { StyledButtonBack, StyledButtonNext, StyledDotGroup, StyledDot, StyledPlay, PlayerWrapper } from './style'

import Arrow from '../arrow'
import arrow from '../../images/arrow.svg'

const Carousel = ({ slides, video, intl }) => {
  const slider = useRef(null)
  const [currentSlide, setCurrentSlide] = useState(0)
  const [videoPlaying, setVideoPlaying] = useState(false)
  const [videoWasLoaded, setVideoWasLoaded] = useState(false)
  const [videoWasPlaying, setVideoWasPlaying] = useState(false)
  const allSlides = [...slides]

  if (video) {
    allSlides.push(video)
  }

  const settings = {
    accessibility: true,
    adaptiveHeight: true,
    arrows: false,
    controls: true,
    dots: false,
    easing: 'cubic-bezier(0.32, 0.08, 0.24, 1)',
    infinite: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    speed: 250,
    beforeChange: (current, next) => {
      setVideoPlaying(false)
      setCurrentSlide(next)
    },
    afterChange: (index) => {
      // If current slide is video and video was already playing
      if (index === slides.length && videoWasPlaying) {
        setVideoPlaying(true)
      }
    },
  }

  const prevLabel = intl.formatMessage({ id: 'carousel.back', defaultMessage: 'Previous' })
  const nextLabel = intl.formatMessage({ id: 'carousel.next', defaultMessage: 'Next' })

  const handlePrevSlide = () => {
    slider.current.slickPrev()
  }

  const handleNextSlide = () => {
    slider.current.slickNext()
  }

  const handleDotClick = (index) => {
    slider.current.slickGoTo(index)
  }

  return (
    <>
      <Box>
        <Slider ref={slider} {...settings}>
          {allSlides.map((slide, i) => {
            if (typeof slide === 'object' && 'file' in slide) { // It's and image!
              return slide && slide.file && <Img key={`image-${i}`} fluid={slide.file.childImageSharp.fluid} alt="" />
            }
            if (typeof slide === 'string') { // It's a video!
              return (
                <PlayerWrapper key="video">
                  {i === currentSlide &&
                    !videoWasLoaded &&
                    setVideoWasLoaded(true)}

                  {/* This is checking if video player was already loaded,
                      if yes, do not unmount the player. */}

                  {(videoWasLoaded || i === currentSlide) && (
                    <ReactPlayer
                      playing={videoPlaying}
                      className="react-player"
                      controls={true}
                      url={video}
                      light
                      width="100%"
                      height="100%"
                      config={{
                        youtube: {
                          playerVars: {
                            fs: 1,
                            modestbranding: 1,
                            playsinline: 0,
                            color: 'white',
                          },
                        },
                      }}
                      onPlay={() => {
                        setVideoPlaying(true)
                        setVideoWasPlaying(true)
                      }}
                    />
                  )}
                </PlayerWrapper>
              )
            }
          })}
        </Slider>
      </Box>

      <Flex justifyContent="space-between" alignItems="center">
        <StyledButtonBack disabled={currentSlide === 0} title={prevLabel} onClick={handlePrevSlide}>
          <Arrow left src={arrow} alt={prevLabel} />
        </StyledButtonBack>

        <StyledDotGroup>
          {allSlides.map((slide, i) => {
            if (typeof slide === 'object') {
              return (
                <StyledDot
                  onClick={() => handleDotClick(i)}
                  key={`image-${i}`}
                  current={i === currentSlide}
                  title={`Image ${i+1}`}
                >
                  <span />
                </StyledDot>
              )
            } else if (typeof slide === 'string') {
              return (
                <StyledPlay
                  onClick={() => handleDotClick(i)}
                  key={'video'}
                  current={i === currentSlide}
                  title="Video"
                >
                  Video
                </StyledPlay>
              )
            }
          })}
        </StyledDotGroup>

        <StyledButtonNext
          disabled={currentSlide === allSlides.length-1}
          title={nextLabel}
          onClick={handleNextSlide}
        >
          <Arrow src={arrow} alt={nextLabel} />
        </StyledButtonNext>
      </Flex>
    </>
  )
}

Carousel.propTypes = {
  slides: PropTypes.array.isRequired,
  video: PropTypes.string,
  intl: PropTypes.object,
}

Carousel.defaultProps = {
  video: null,
}

export default injectIntl(Carousel)
