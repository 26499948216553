import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import Layout from '../layouts'
import SEO from '../components/seo'
import Product from '../components/product'
import Container from '../components/container'

const ProductPage = (props) => {
  const { data } = props

  const product = data.markdownRemark
  const nextProduct = data.nextProduct || null
  const { freeShippingTotal } = data.site.siteMetadata

  return (
    <Layout {...props} locale={props.pageContext.locale}>
      <SEO
        isProduct
        title={product.frontmatter.title || ''}
        description={product.excerpt}
        image={
          product.frontmatter.featuredImage &&
          product.frontmatter.featuredImage.childImageSharp.fluid.src
        }
        product={product}
      />

      <Container>
        <Product
          title={product.frontmatter.title || ''}
          html={product.html || ''}
          excerpt={product.excerpt || ''}
          sku={product.frontmatter.sku || ''}
          price={product.frontmatter.price}
          currency={product.frontmatter.currency || 'USD'}
          shippingPrice={product.frontmatter.shipping_price || 0}
          freeShippingTotal={freeShippingTotal}
          properties={product.frontmatter.properties || []}
          video={product.frontmatter.video || null}
          photogallery={product.frontmatter.photogallery || null}
          nextProduct={nextProduct && nextProduct.frontmatter}
          accessories={product.frontmatter.accessories || null}
        />
      </Container>
    </Layout>
  )
}

ProductPage.propTypes = {
  pageContext: PropTypes.object,
  data: PropTypes.object,
}

export default ProductPage

export const pageQuery = graphql`
  query ProductBySlug($slug: String!, $nextSlug: String) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      excerpt(pruneLength: 100)
      html
      frontmatter {
        slug
        currency
        price
        shipping_price
        sku
        title
        video
        featuredImage {
          childImageSharp {
            fluid(maxWidth: 320) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        photogallery {
          id
          file {
            childImageSharp {
              fluid(maxWidth: 800) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        properties {
          id
          label
          value
          unit
        }
        # accessories {
        #   id
        #   title
        #   description
        #   price
        #   currency
        #   photos {
        #     id
        #     file {
        #       childImageSharp {
        #         fixed(width: 400, height: 400) {
        #           ...GatsbyImageSharpFixed
        #         }
        #       }
        #     }
        #   }
        # }
      }
    }
    nextProduct: markdownRemark(frontmatter: { slug: { eq: $nextSlug } }) {
      frontmatter {
        title
        slug
        featuredImage {
          childImageSharp {
            fluid(maxWidth: 320) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    site {
      siteMetadata {
        title
        languages {
          defaultLangKey
          langs
        }
        freeShippingTotal
      }
    }
  }
`
